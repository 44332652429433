// src/utils/fieldUtils.js

export const getSignalStatus = (rssi) => {
    const rssiValue = parseInt(rssi, 10);

    if (rssiValue > -85) {
        return 'well_signal';
    } else if (rssiValue <= -85 && rssiValue >= -95) {
        return 'half_signal';
    } else if (rssiValue <= -96 && rssiValue >= -119) {
        return 'low_signal';
    } else if (rssiValue <= -120) {
        return 'no_signal';
    }
};

export const getModel = (field) => {
    if (field['active_prediction_model']){
        let value = 0;
        let color = 'green';
        let generation = 0;
        let value_j2 = 100;
        let color_j2 = 'red';
        let generation_j2 = 0;
        if (field['active_prediction_model']['last_log_entry']){
            value = parseInt(field['active_prediction_model']['last_log_entry']['ratio_eggs'] * 100);
            generation = field['active_prediction_model']['last_log_entry']['generation']
            if (value >= 80 && value < 100) {
                color = 'orange';
            } else if (value >= 100 && value < 120) {
                value = 100;
                color = 'red';
            } else if (value >= 120) {
                value = 100;
                color = 'blue';
            }
        }
        if (field['active_prediction_model']['last_log_entry_j2']){
            generation_j2 = field['active_prediction_model']['last_log_entry_j2']['generation'] 
            value_j2 = field['active_prediction_model']['last_log_entry_j2']['ratio_larvicide']
            color_j2 = field['active_prediction_model']['last_log_entry_j2']['color']
        }
        return ['Nematode active',generation,value,color,generation_j2,value_j2,color_j2];
    }
    else if (field['active_solarization']){
        let degrees = 0;
        if (field['active_solarization']['degrees']){
            degrees = parseInt(field['active_solarization']['degrees']);
        }
        let widthBar = (degrees/3000) * 100;
        return ['Solarization active',degrees,widthBar];
    }
    return ['No model'];
};

export const getModelName = (field) => {
    if (field['active_prediction_model']){
        return 'nematode';
    }
    else if (field['active_solarization']){
        return 'solarization';
    }
    return 'no_model';
};

export const getModelId = (field) => {
    if (field['active_prediction_model']){
        return ["N",field['active_prediction_model']['id']];
    }
    else if (field['active_solarization']){
        return ["S",field['active_solarization']['id']];
    }
    return null;
};
export const getOwner = (field) => {
    return field['owner']['email'];
};

export const getColor = (color) => {
    switch (color) {
        case 'red':
            return '#ED5F55';
        case 'orange':
            return '#F6894E';
        case 'green':
            return '#7ECA22';
        case 'blue':
            return '#67AAFB';
    }
}

export const getColorFooter = (color) => {
    switch (color) {
        case 'red':
            return '#D5554C';
        case 'orange':
            return '#DC7B46';
        case 'green':
            return '#71B61F';
        case 'blue':
            return '#5D99E2';
    }
}

export const getColorCard = (model) => {
    if (model[0] === 'Solarization active') {
        return '#F5A544';
    } else if (model[0] === 'No model') {
        return '#C7C7C7';
    } else {
        if (model[3] === 'red' || model[6] === 'red') {
            return getColor('red');
        } 
        if (model[3] === 'orange' || model[6] === 'orange') {
            return getColor('orange');
        }
        if (model[3] === 'blue' || model[6] === 'blue') {
            return getColor('blue');
        }
        if (model[3] === 'green' || model[6] === 'green') {
            return getColor('green');
        }
    }
}

export const getColorCardFooter = (model) => {
    if (model[0] === 'Solarization active') {
        return '#DB943C';
    } else if (model[0] === 'No model') {
        return '#B3B3B3';
    } else {
        if (model[3] === 'red' || model[6] === 'red') {
            return getColorFooter('red');
        } 
        if (model[3] === 'orange' || model[6] === 'orange') {
            return getColorFooter('orange');
        }
        if (model[3] === 'blue' || model[6] === 'blue') {
            return getColorFooter('blue');
        }
        if (model[3] === 'green' || model[6] === 'green') {
            return getColorFooter('green');
        }
    }
}