import React, { useState, useRef } from 'react';
import { interpolateData } from '../actions/fieldsActions';
import { useTranslation } from 'react-i18next';
import { InputNumber } from 'primereact/inputnumber';
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

export const ModalInterpolate = ({ isOpen, onClose, sensorId }) => {
    const toast = useRef(null);
    const { t } = useTranslation();
    const [value, setValue] = useState(1);
    const [visible, setVisible] = useState(isOpen);

    // Sincroniza el estado 'visible' cuando 'isOpen' cambia
    React.useEffect(() => {
        setVisible(isOpen);
    }, [isOpen]);

    const handleAccept = async () => {
        if (value < 1 || value > 10) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: t('interpolate_modal.error_value'), life: 6000 });
            return;
        }
        const response = await interpolateData(value, sensorId);
        if (typeof response === 'string') {
            toast.current.show({ severity: 'error', summary: 'Error', detail: t(`field.${response}`), life: 6000 });
        } else {
            setVisible(false);
            onClose();
        }
    };

    const handleClose = async () => {
        const response = await interpolateData(0, sensorId);
        if (typeof response === 'string') {
            toast.current.show({ severity: 'error', summary: 'Error', detail: t(`field.${response}`), life: 6000 });
        } else {
            setVisible(false);
            onClose();
        }
    };

    const footer = (
        <div>
            <Button label={t('interpolate_modal.cancel_label')} onClick={handleClose} />
            <Button label={t('interpolate_modal.accept_label')} onClick={handleAccept} />
        </div>
    );

    return (
        <>
            <Toast ref={toast} position="top-center" className="w-11" />
            <Dialog
                className="w-11"
                visible={visible}
                onHide={handleClose}
                header={t('interpolate_modal.header')}
                footer={footer}
                modal
                closable={false}
            >
                <div className="number-picker-container flex flex-column align-items-center">
                    <h3>{t('interpolate_modal.message')}</h3>
                    <InputNumber
                        value={value}
                        onValueChange={(e) => setValue(e.value)}
                    />
                </div>
            </Dialog>
        </>
    );
};
