const apiUrl = process.env.REACT_APP_API_URL;

export const getTemperatureData = async (fieldId, interval, timestamp_min, timestamp_max) => {
    const localtoken = localStorage.getItem('authToken');

    try {
        const params = new URLSearchParams({
            field: fieldId,
            key: 'temperature-underground',
            interval: interval,
            timestamp_min: timestamp_min,
            timestamp_max: timestamp_max
        }).toString();
        
        const response = await fetch(`${apiUrl}/api/v1/sensor_key_values/time_series/?${params}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${localtoken}`,
            },
        });

        if (response.ok) {
            const data = await response.json();
            return data

        } else {
            console.log("Error")
        }
    } catch (error) {
        console.log("Error")
    }
};

export const getPredictionModel = async (predictionModelId, timestamp_min, timestamp_max) => {
    const localtoken = localStorage.getItem('authToken');
    let allResults = [];
    let nextPageUrl = `${apiUrl}/api/v1/nematool/prediction_model_log_entries/?${new URLSearchParams({
        prediction_model: predictionModelId,
        date_min: timestamp_min,
        date_max: timestamp_max
    }).toString()}`;

    try {
        while (nextPageUrl) {
            const response = await fetch(nextPageUrl, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${localtoken}`,
                },
            });
            if (response.ok) {
                const data = await response.json();
                allResults = allResults.concat(data.results);
                nextPageUrl = data.next;
            } else {
                console.log("Error");
                break;
            }
        }
        return allResults;
    } catch (error) {
        console.error("Error en la solicitud:", error);
    }
};

export const getPredictionModelJ2 = async (predictionModelId, timestamp_min, timestamp_max) => {
    const localtoken = localStorage.getItem('authToken');
    let allResults = [];
    let nextPageUrl = `${apiUrl}/api/v1/nematool/prediction_model_j2_log_entries/?${new URLSearchParams({
        prediction_model: predictionModelId,
        date_min: timestamp_min,
        date_max: timestamp_max
    }).toString()}`;

    try {
        while (nextPageUrl) {
            const response = await fetch(nextPageUrl, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${localtoken}`,
                },
            });

            if (response.ok) {
                const data = await response.json();
                allResults = allResults.concat(data.results);
                nextPageUrl = data.next;
            } else {
                console.log("Error");
                break;
            }
        }
        return allResults;
    } catch (error) {
        console.log("Error");
    }
};

export const getSolarizationData = async (fieldId, timestamp_min, timestamp_max) => {
    const localtoken = localStorage.getItem('authToken');
    let allResults = [];
    let nextPageUrl = `${apiUrl}/api/v1/nematool/solarizations_log_entries/?${new URLSearchParams({
        field: fieldId,
        date_min: timestamp_min,
        date_max: timestamp_max
    }).toString()}`;

    try {
        while (nextPageUrl) {
            const response = await fetch(nextPageUrl, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${localtoken}`,
                },
            });

            if (response.ok) {
                const data = await response.json();
                allResults = allResults.concat(data.results);
                nextPageUrl = data.next;
            } else {
                console.log("Error");
                break;
            }
        }
        return allResults;
    } catch (error) {
        console.log("Error");
    }
};

export const getExcelPredictionModel = async (fieldId, timestamp_min, timestamp_max, timezoneOffset) => {
    const localtoken = localStorage.getItem('authToken');

    try {
        const params = new URLSearchParams({
            field: fieldId,
            key: 'temperature-underground',
            interval: 'D',
            date_min: timestamp_min,
            date_max: timestamp_max,
            timezone_offset: timezoneOffset,
            sourceType: 'sensor'
        }).toString();
        
        const response = await fetch(`${apiUrl}/api/v1/nematool/prediction_model_log_entries/excel/?${params}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${localtoken}`,
            },
        });

        if (response.ok) {
            return response

        } else {
            console.log("Error")
        }
    } catch (error) {
        console.log(error)
        console.log("Error")
    }
};

export const getExcelSolarizationData = async (fieldId, timestamp_min, timestamp_max, timezoneOffset) => {
    const localtoken = localStorage.getItem('authToken');

    try {
        const params = new URLSearchParams({
            field: fieldId,
            key: 'temperature-underground',
            interval: 'H',
            date_min: timestamp_min,
            date_max: timestamp_max,
            timezone_offset: timezoneOffset,
        }).toString();
        
        const response = await fetch(`${apiUrl}/api/v1/nematool/solarizations_log_entries/excel/?${params}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${localtoken}`,
            },
        });

        if (response.ok) {
            return response

        } else {
            console.log("Error")
        }
    } catch (error) {
        console.log(error)
        console.log("Error")
    }
};


export const getExcelTemperatureData = async (fieldId, interval, timestamp_min, timestamp_max, timezoneOffset) => {
    const localtoken = localStorage.getItem('authToken');

    try {
        const params = new URLSearchParams({
            field: fieldId,
            key: 'temperature-underground',
            interval: interval,
            date_min: timestamp_min,
            date_max: timestamp_max,
            timezone_offset: timezoneOffset,
        }).toString();
        
        const response = await fetch(`${apiUrl}/api/v1/sensor_key_values/excel/?${params}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${localtoken}`,
            },
        });

        if (response.ok) {
            return response

        } else {
            console.log("Error")
        }
    } catch (error) {
        console.log("Error")
    }
};