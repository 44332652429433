import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import nematool_logo_RGB from '../assets/nematool_logo_print_CMYK.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faBell, faArrowLeft } from '@fortawesome/free-solid-svg-icons'; 
import { Image } from 'primereact/image';
import { Sidebar } from 'primereact/sidebar';
import { logout } from '../actions/userActions';
import { Button } from 'primereact/button';
import { useTranslation } from 'react-i18next';
import { ModalGeneric } from './ModalGeneric';
import { Ripple } from 'primereact/ripple';

export const Header = ({ isMain = false, isField = false, goBack = null, isCreateField = false, stepIndex = 0, prevStep }) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [visible, setVisible] = useState(false);
    const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);

    const handleSettings = () => {
        setVisible(true);
    };

    const handleGoBack = () => {
        if (isCreateField) {
            if (stepIndex === 0) {
                navigate(-1);
            } else {
                prevStep();
            }
        } else if (isField) {
            navigate('/fields');
        } else if (goBack == null) {
            navigate(-1);
        } else {
            navigate(goBack);
        }
    };

    const handleNotifications = () => {
        navigate('/notifications');
    };

    const handleUpdateUser = () => {
        navigate('/updateUser');
    };

    const handleLogOut = () => {
        setIsLogoutModalOpen(true);
    };

    const confirmLogOut = () => {
        logout(navigate);
        setIsLogoutModalOpen(false);
    };

    const cancelLogOut = () => {
        setIsLogoutModalOpen(false);
    };

    const redirectToTerms = () => {
        window.location.href = "https://nematool.com/Nematool-UserLicenseAgreement.pdf";
    };

    const redirectToPolicy = () => {
        window.location.href = "https://nematool.com/Nematool-DataPrivacyStatement.pdf";
    };

    return (
        <div className="flex justify-content-between mt-3 w-11">
            <FontAwesomeIcon 
                icon={isMain ? faBars : faArrowLeft} 
                style={isMain ? { 
                    color: 'var(--primary-color)', 
                    fontSize: '24px', 
                    cursor: 'pointer',
                    marginTop: '9px'  
                } : { 
                    paddingRight: '3px', 
                    color: 'var(--primary-color)', 
                    fontSize: '24px', 
                    cursor: 'pointer',
                    marginTop: '9px' 
                }} 
                onClick={isMain ? handleSettings : handleGoBack} 
                className="no-tap-highlight"
            />
            <Image 
                src={nematool_logo_RGB} 
                alt="Nematool Logo" 
                width={window.innerWidth < 500 ? "250px" : "300px"} 
            />
            <FontAwesomeIcon 
                icon={faBell} 
                style={{ 
                    visibility: isMain ? 'visible' : 'hidden', 
                    color: 'var(--primary-color)', 
                    fontSize: '24px', 
                    cursor: 'pointer',
                    outline: 'none',
                    marginTop: '9px'  
                }} 
                onClick={handleNotifications} 
            />
            <Sidebar
                visible={visible}
                onHide={() => setVisible(false)}
                style={{ width: '280px' }}
                content={({ closeIconRef, hide }) => (
                    <div className="min-h-screen flex relative lg:static surface-ground">
                        <div id="app-sidebar-2" className="surface-section h-screen block flex-shrink-0 absolute lg:static left-0 top-0 z-1 border-right-1 surface-border select-none" style={{ width: '280px' }}>
                            <div className="flex flex-column h-full">
                                <div className="flex align-items-center justify-content-between px-4 pt-3 flex-shrink-0">
                                    <span className="inline-flex align-items-center gap-2">
                                        <span className="font-semibold text-2xl text-primary">{t('settings.title')}</span>
                                    </span>
                                    <span>
                                        <Button type="button" ref={closeIconRef} onClick={(e) => hide(e)} icon="pi pi-times" rounded outlined className="h-2rem w-2rem"></Button>
                                    </span>
                                </div>
                                <div className="overflow-y-auto">
                                    <ul className="list-none pl-2 overflow-hidden">
                                        <li>
                                            <a className="p-ripple flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors w-full" onClick={handleUpdateUser}>
                                                <i style={{ color: 'black' }} className="pi pi-user-edit mr-2"></i>
                                                <span style={{ color: 'black' }} className="font-medium">{t('settings.update_user')}</span>
                                                <Ripple />
                                            </a>
                                        </li>
                                        <li>
                                            <a className="p-ripple flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors w-full" onClick={() => navigate('/license')}>
                                                <i style={{ color: 'black' }} className="pi pi-key mr-2"></i>
                                                <span style={{ color: 'black' }} className="font-medium">{t('settings.activate_license')}</span>
                                                <Ripple />
                                            </a>
                                        </li>
                                        <li>
                                            <a className="p-ripple flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors w-full" onClick={redirectToTerms}>
                                                <i style={{ color: 'black' }} className="pi pi-file mr-2"></i>
                                                <span style={{ color: 'black' }} className="font-medium">{t('settings.terms_and_conditions')}</span>
                                                <Ripple />
                                            </a>
                                        </li>
                                        <li>
                                            <a className="p-ripple flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors w-full" onClick={redirectToPolicy}>
                                                <i style={{ color: 'black' }} className="pi pi-file mr-2"></i>
                                                <span style={{ color: 'black' }} className="font-medium">{t('settings.privacy_policy')}</span>
                                                <Ripple />
                                            </a>
                                        </li>
                                        <li>
                                            <a className="p-ripple flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors w-full" onClick={handleLogOut}>
                                                <i style={{ color: 'black' }} className="pi pi-sign-out mr-2"></i>
                                                <span style={{ color: 'black' }} className="font-medium">{t('settings.log_out')}</span>
                                                <Ripple />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            ></Sidebar>

            <ModalGeneric 
                isOpen={isLogoutModalOpen} 
                onClose={cancelLogOut} 
                onConfirm={confirmLogOut}
                message={t('settings.logout_message')}
            />
        </div>
    );
};
