import React from 'react';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { useTranslation } from 'react-i18next';

export const ModalGeneric = ({ isOpen, onClose, onConfirm, message }) => {
    const { t } = useTranslation();
    return (
        <ConfirmDialog
            className='w-11'
            visible={isOpen}
            onHide={onClose}
            message={message}
            header={t('settings.advice')}
            icon="pi pi-exclamation-triangle"
            accept={onConfirm}
            reject={onClose}
            acceptLabel={t('settings.confirm')}
            rejectLabel={t('settings.cancel')}
        />
    );
};
