import React, { useState, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faPlus } from '@fortawesome/free-solid-svg-icons'; 
import { getGuests, deleteGuest, addGuest } from '../actions/fieldsActions';
import { useTranslation } from 'react-i18next';
import { Toast } from 'primereact/toast';
import { InputText } from 'primereact/inputtext';

export const ModalShared = ({ isOpen, onClose, sharedUsers, fieldId, setSharedUsers }) => {
    const { t } = useTranslation();
    const toast = useRef(null);
    const [errorMessage, setErrorMessage] = useState('');
    const [email, setEmail] = useState(''); // Estado para manejar el valor del input

    const handleInsertGuest = async (fieldId, email, sharedUsers, setSharedUsers, setErrorMessage, setEmail, toast, t) => {
        // Verificar si el correo ya está en la lista
        const isAlreadyShared = sharedUsers.some(user => user.user.email === email);

        if (isAlreadyShared) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: t('field.user_already_shared'), life: 6000 });
            return;
        }

        try {
            if (email === '') {
                toast.current.show({ severity: 'error', summary: 'Error', detail: t('field.empty_email'), life: 6000 });
                return;
            } 
            const response = await addGuest(fieldId, email);
            if (response === 200) {
                const guestsResponse = await getGuests(fieldId);
                setSharedUsers(guestsResponse.results);
                setErrorMessage('');
                setEmail('');
            } else if (response === 404) {
                toast.current.show({ severity: 'error', summary: 'Error', detail: t('field.user_not_found'), life: 6000 });
            } else {
                toast.current.show({ severity: 'error', summary: 'Error', detail: t('field.error_adding_user'), life: 6000 });
            }
        } catch (error) {
            console.error(error);
            toast.current.show({ severity: 'error', summary: 'Error', detail: t('field.error_adding_user'), life: 6000 });
        }
    };

    const handleDeleteGuest = async (fieldId, userId, setSharedUsers) => {
        try {
            const response = await deleteGuest(fieldId, userId);
            if (response.status === 200) {
                setSharedUsers(prevUsers => prevUsers.filter(user => user.user.id !== userId));
            } else {
                console.error('Error deleting user:', response.statusText);
            }
        } catch (error) {
            console.error('Error deleting user:', error);
        }
    };

    if (!isOpen) return null;

    return (
        <>
        <div onClick={(e) => e.stopPropagation()} onClose={(e) => e.stopPropagation()}>
            <Toast 
                ref={toast}
                style={{ zIndex: 1 }}
                position="top-center" 
                className="w-11" 
        />
        </div>
        <div 
            className='w-full h-full flex justify-content-center align-items-center fixed top-0 left-0 right-0 bottom-0 bg-black-alpha-50' 
            style={{ zIndex: 2 }}
            onClick={(e) => e.stopPropagation()}
        >
            <div
                className='w-11 bg-white p-3 border-round-lg shadow-2 text-center'
                onClick={(e) => e.stopPropagation()}
            >
                <div className="flex justify-content-between align-items-center mb-3">
                    <h2 className='m-0 text-color-secondary'>{t('field.shared_users')}</h2>
                    <span
                        className='text-color-secondary'
                        style={{
                            cursor: 'pointer',
                            fontSize: '2.5rem',
                            fontWeight: 'bold'
                        }}
                        onClick={onClose}
                    >
                        &times;
                    </span>
                </div>
                {errorMessage && <strong><p className="error-message">{errorMessage}</p></strong>}
                <div className="relative flex align-items-center w-full">
                    <InputText
                        className="flex-1 p-2 pr-4 border-1 border-round-sm text-sm"
                        type="text"
                        id="email"
                        name="email"
                        placeholder={t('field.enter_user_email')}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)} />
                    <span
                        className='text-color-secondary'
                        style={{
                            position: 'absolute',
                            right: '10px',
                            top: '50%',
                            transform: 'translateY(-50%)',
                            cursor: 'pointer'
                        }}
                        onClick={() => handleInsertGuest(
                            fieldId,
                            email,
                            sharedUsers,
                            setSharedUsers,
                            setErrorMessage,
                            setEmail,
                            toast,
                            t
                        )}
                    >
                        <FontAwesomeIcon icon={faPlus} />
                    </span>
                </div>

                <div className="flex flex-column pl-1 pr-1">
                {sharedUsers.length > 0 ? (
                    
                    sharedUsers.map((user) => (
                        <div key={user.user.id} className="flex justify-content-center align-items-center">
                            <p style={{
                                color: 'black',
                                flex: '1 1 0%',
                                display: 'flex'
                            }}>{user.user.email}</p>
                            <FontAwesomeIcon
                                className='text-color-secondary'
                                icon={faTrash}
                                style={{ cursor: 'pointer' }}
                                onClick={() => handleDeleteGuest(fieldId, user.user.id, setSharedUsers)} />
                        </div>
                    ))
                ) : (
                    <p className='text-color-secondary'>{t('field.no_shared_users')}</p> // Mensaje alternativo si no hay usuarios
                )}
                </div>
            </div>
        </div></>
    );
};
