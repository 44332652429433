import React, { useState, useEffect } from 'react';
import CacheBuster from 'react-cache-buster';
import packageInfo from '../package.json';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { Login } from './components/Login';
import { Register } from './components/Register';
import { RecoverPassword } from './components/RecoverPassword';
import { Fields } from './components/Fields';
import { Field } from './components/Field';
import PrivateRoute from './components/PrivateRoute';
import { AddLicense } from './components/AddLicense';
import { LinkSensor } from './components/LinkSensor';
import { Notifications } from './components/Notifications';
import { RestorePassword } from './components/RestorePassword';
import { UpdateUser } from './components/UpdateUser';
import { CreateField } from './components/CreateField';
import { License } from './components/License';
import { PrimeReactProvider } from 'primereact/api';
import 'primeflex/primeflex.css';
import 'primereact/resources/themes/saga-green/theme.css';
import 'primereact/resources/primereact.css';
import 'primeicons/primeicons.css';
import { ModalNetwork } from './components/ModalNetwork';
import './i18n';


function App() {
  const [isNetworkModalOpen, setIsNetworkModalOpen] = useState(!navigator.onLine);
  const isProduction = process.env.NODE_ENV === 'production';
  //const version = process.env.VERSION;
  /*
  useEffect(() => {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.register('/Service-worker.js')
        .then(() => console.log('Service Worker registrado'))
        .catch((error) => console.error('Error al registrar el Service Worker:', error));
    }
  }, []);*/

  const isAuthenticated = () => {
    return !!localStorage.getItem('authToken');
  };

  useEffect(() => {
    const handleOnline = () => setIsNetworkModalOpen(false);
    const handleOffline = () => setIsNetworkModalOpen(true);

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  return (
    <>
      <CacheBuster
        currentVersion={packageInfo.version}
        isEnabled={isProduction} //If false, the library is disabled.
        isVerboseMode={true} //If true, the library writes verbose logs to console.
        metaFileDirectory={'.'} //If public assets are hosted somewhere other than root on your server.
      />
      <Router>
        <PrimeReactProvider>
          <ModalNetwork
            isOpen={isNetworkModalOpen}
            message={"No internet connection. Please check your network settings."}
            onClose={() => setIsNetworkModalOpen(false)}
            onConfirm={() => setIsNetworkModalOpen(false)} />
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/recoverPassword" element={<RecoverPassword />} />
            <Route path="/restorePassword/:hash" element={<RestorePassword />} />
            <Route path="/updateUser" element={<PrivateRoute element={UpdateUser} />} />
            <Route path="/fields" element={<PrivateRoute element={Fields} />} />
            <Route path="/field/:fieldId" element={<PrivateRoute element={Field} />} />
            <Route path="/createField" element={<PrivateRoute element={CreateField} />} />
            <Route path="/create/field/license" element={<PrivateRoute element={AddLicense} />} />
            <Route path="/link/sensor/:fieldId" element={<PrivateRoute element={LinkSensor} />} />
            <Route path="/notifications" element={<PrivateRoute element={Notifications} />} />
            <Route path="/license" element={<PrivateRoute element={License} />} />
            <Route path="*" element={isAuthenticated() ? <Navigate to="/fields" replace /> : <Navigate to="/login" replace />} />
          </Routes>
        </PrimeReactProvider>
      </Router>
    </>
  );
}

export default App;
