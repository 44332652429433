import React from 'react';
import { deleteGuest } from '../actions/fieldsActions';
import { useTranslation } from 'react-i18next';
import { ConfirmDialog } from 'primereact/confirmdialog';

export const ModalRejectShared = ({ isOpen, onClose, fieldId, userId, setTrigger }) => {
    const { t } = useTranslation();

    const handleDeleteGuest = async (fieldId, userId) => {
        try {
            const response = await deleteGuest(fieldId, userId);
            if (response.status === 200) {
                setTrigger(prev => !prev);
            } else {
                console.error('Error deleting user:', response.statusText);
            }
        } catch (error) {
            console.error('Error deleting user:', error);
        }
    };

    if (!isOpen) return null;

    return (
        <div onClick={(e) => e.stopPropagation()}>
        <ConfirmDialog
            visible={isOpen}
            onHide={onClose}
            icon='pi pi-exclamation-triangle'
            className='w-11'
            message={t('field_card.reject_shared_message')}
            header={t('field_card.reject_shared')}
            acceptLabel={t('field_card.reject')}
            rejectClassName="hidden"
            defaultFocus="none"
            onClick={e => e.stopPropagation()}
            focusOnShow={false}
            accept={async () => {
                await handleDeleteGuest(fieldId, userId);
            }}
        />
        </div>
    );
};
