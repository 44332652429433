import React, { useRef, useEffect } from 'react';
import { Toast } from 'primereact/toast';
import { ModalInstall } from './ModalInstall';
import { ModalUpdateUser } from './ModalUpdateUser';
import { Header } from './Header';

export const PostLoginWrapper = ({ children, error, isMain, isField, isCreateField, stepIndex, prevStep }) => {
    const toast = useRef(null);

    useEffect(() => {
        if (error) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: error, life: 6000 });
        }
    }, [error]);

    return (
        <>
            <ModalInstall />
            <ModalUpdateUser />
            <div className="flex flex-column align-items-center w-full" style={{ height: '100vh' }}>
                <div className="flex flex-column align-items-center w-full" style={{ maxWidth: '500px' }}>
                    <Toast ref={toast} position="top-center" className="w-11" />
                    <Header
                        isMain={isMain}
                        isField={isField}
                        isCreateField={isCreateField}
                        stepIndex={stepIndex}
                        prevStep={prevStep}
                    />
                    {children}  {/* Aquí se renderiza el contenido adicional */}
                </div>
            </div>
        </>
    );
};
