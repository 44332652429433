import React from 'react';
import { PostLoginWrapper } from './PostLoginWrapper';

export const License = () => {
    return (
        <PostLoginWrapper>
            <div className='text-center'>
                <h2>Feature currently not available.</h2>
            </div>
        </PostLoginWrapper>
    );
}
