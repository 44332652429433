import React, { useState, useEffect } from 'react';
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';
const gmaps_api_key = process.env.REACT_APP_GMAPS_API_KEY;

const containerStyle = {
    width: '100%',
    height: '400px'
};

const defaultCenter = {
    lat: 0,
    lng: 0
};

const Map = ({ onMapClick, onMapLoad, latitude, longitude, clickable = true }) => {
    const [position, setPosition] = useState(null);
    const [clickedPosition, setClickedPosition] = useState(null);

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: gmaps_api_key
    });

    useEffect(() => {
        if (latitude && longitude) {
            const manualPosition = { lat: latitude, lng: longitude };
            setPosition(manualPosition);
            onMapLoad(manualPosition);
        } else if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const initialPosition = {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude,
                    };
                    setPosition(initialPosition);
                    onMapLoad(initialPosition);
                },
                (error) => {
                    setPosition(defaultCenter);
                    onMapLoad(defaultCenter);
                }
            );
        } else {
            setPosition(defaultCenter);
            onMapLoad(defaultCenter);
        }
    }, [latitude, longitude, onMapLoad]);

    const handleMapClick = (event) => {
        if (!clickable) return; // No hacer nada si clickable es false

        const lat = event.latLng.lat();
        const lng = event.latLng.lng();
        const newPosition = { lat, lng };
        setClickedPosition(newPosition);
        onMapClick(newPosition);
    };

    return isLoaded ? (
            <GoogleMap
                mapContainerStyle={containerStyle}
                center={position || defaultCenter}
                zoom={15}
                onClick={handleMapClick}
                options={{
                    mapTypeId: 'satellite', // Configura el mapa en modo satélite
                    disableDefaultUI: false, // Deshabilita todos los controles predeterminados
                    zoomControl: false, // Opcional: deshabilita el control de zoom
                    streetViewControl: false, // Opcional: deshabilita el control de Street View
                    mapTypeControl: false, // Opcional: deshabilita el control de tipo de mapa
                    fullscreenControl: false, // Opcional: deshabilita el control de pantalla completa
                    rotateControl: false, // Deshabilita los botones de rotación
                }}
            >
                {clickedPosition && (
                    <Marker position={clickedPosition} />
                )}
                {position && !clickedPosition && (
                    <Marker position={position} />
                )}
            </GoogleMap>
    ) : <p>Loading map...</p>;
}

export default Map;
