import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export const ModalNetwork = ({ isOpen, onClose, onConfirm, message }) => {
    const { t } = useTranslation();
    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
    }, [isOpen]);

    if (!isOpen) return null;

    return (
        <div className="flex fixed top-0 left-0 w-full h-full z-5 bg-black-alpha-50 justify-content-center align-items-center">
            <div className="bg-white p-4 border-round-2xl w-11 shadow-2">
                <div className="flex flex-column align-items-center text-center">
                    <svg xmlns="http://www.w3.org/2000/svg" width="90" height="90" fill="#FF5733" viewBox="0 0 48 48">
                        <path d="M22 30h4v4h-4zm0-16h4v12h-4zm1.99-10C12.94 4 4 12.95 4 24s8.94 20 19.99 20S44 35.05 44 24 35.04 4 23.99 4zM24 40c-8.84 0-16-7.16-16-16S15.16 8 24 8s16 7.16 16 16-7.16 16-16 16z"></path>
                    </svg>
                    <div className="text-xl mt-3">{t('network_modal.message')}</div>
                </div>
            </div>
            <div className="modal-blocker"></div>
        </div>
    );
};